// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('drivers/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/drivers')
  return response.data.result
})

export const getData = createAsyncThunk('drivers/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/drivers?', {
    params
  })
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('drivers/getUser', async id => {
  if (id !== null) {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/admin/drivers/${id}`)
    return response.data.data
  } else {
    return null
  }
})
export const resetUser = createAsyncThunk('drivers/resetUser', () => {
  return null
})

export const addUser = createAsyncThunk('drivers/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/admin/drivers', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().drivers.params))
  return user
})

export const updateUser = createAsyncThunk('drivers/updateUser', async (data, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/drivers/${data?.id}`, data?.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().drivers.params))
  return data.user
})

export const deleteUser = createAsyncThunk('drivers/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/admin/drivers/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().drivers.params))
  return id
})

export const activeUser = createAsyncThunk('drivers/activeUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/drivers/status/${user.id}`, user.data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().drivers.params))
  return user
})
export const addNewPay = createAsyncThunk('drivers/addNewPay', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/payment/downPayment/${user.id}`, user.data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().drivers.params))
  return user
})

export const driversSlice = createSlice({
  name: 'drivers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default driversSlice.reducer
