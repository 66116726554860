// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('vehicles/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/v1/dashboard/countryVisa')
  return response.data.result
})

export const getData = createAsyncThunk('vehicles/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/types?', {
    params
  })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('vehicles/getUser', async id => {
  if (id !== null) {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/v1/dashboard/countryVisa/${id}`)
    return response.data.data
  } else {
    return null
  }
})
export const resetUser = createAsyncThunk('vehicles/resetUser', () => {
  return null
})

export const addUser = createAsyncThunk('vehicles/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/admin/types', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().vehicles.params))
  return user
})

export const updateUser = createAsyncThunk('vehicles/updateUser', async (data, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/types/${data?.id}`, data?.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().vehicles.params))
  return data.user
})

export const deleteUser = createAsyncThunk('vehicles/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/admin/types/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().vehicles.params))
  // await dispatch(getAllData())
  return id
})

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default vehiclesSlice.reducer
