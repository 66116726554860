// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import notifications from './notification'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import user from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import reset from '@src/views/pages/authentication/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import vehicles from '@src/views/apps/vehicles/store'
import brands from '@src/views/apps/brands/store'
import subBrands from '@src/views/apps/sub-brands/store'
import payments from '@src/views/apps/payment/store'
import drivers from '@src/views/apps/drivers/store'
import cars from '@src/views/apps/cars/store'
import passangers from '@src/views/apps/passangers/store'
import trips from '@src/views/apps/trips/store'
import colors from '@src/views/apps/colors/store'
import admins from '@src/views/apps/admins/store'
import roles from '@src/views/apps/roles/store'
import settings from '@src/views/apps/setting/store'
import statiscs from '@src/views/dashboard/analytics/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  kanban,
  navbar,
  notifications,
  statiscs,
  vehicles,
  colors,
  cars,
  settings,
  passangers,
  trips,
  admins,
  roles,
  payments,
  drivers,
  brands,
  subBrands,
  layout,
  user,
  invoice,
  calendar,
  ecommerce,
  reset,
  dataTables,
  permissions
}

export default rootReducer
