// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('notifications/getAllData', async () => {
  const response = await axios.get('https://driver.bw-businessworld.com/api/v1/web/brand')
  return response.data.result
})

export const getNotification = createAsyncThunk('notifications/getNotification', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/users/getAllNotificatios?with_paginate=yes', {
    params
  })
  return {
    params,
    data: response.data.data.notifications.data,
    totalPages: response.data.data.notifications?.total,
    un_read: response.data.data.unseen,
    loading: true
  }
})

export const addNew = createAsyncThunk('notifications/addNew', async noti => {
  return noti
})
export const resetUser = createAsyncThunk('segment/resetUser', () => {
  return null
})
export const getUsers = createAsyncThunk('notifications/getUsers', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/users?paginate=no')
  return response.data.data
})
export const getDrivers = createAsyncThunk('notifications/getDrivers', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/drivers?paginate=no')
  return response.data.data
})
export const addUser = createAsyncThunk('notifications/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post('https://driver.bw-businessworld.com/api/v1/web/notification/store', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getNotification(getState().notifications.params))
  return user
})

export const updateUser = createAsyncThunk('notifications/updateUser', async (user, { dispatch, getState }) => {
  await axios
    .post(`https://driver.bw-businessworld.com/api/v1/web/brand/${user?.id}/update`, user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getNotification(getState().notifications.params))
  return user
})

export const deleteUser = createAsyncThunk('notifications/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(`https://driver.bw-businessworld.com/api/v1/web/brand/${id}/delete`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getNotification(getState().notifications.params))
  return id
})
export const readNotification = createAsyncThunk(
  'notifications/readNotification',
  async (id, { dispatch, getState }) => {
    await axios
      .get(`https://driver.bw-businessworld.com/api/v1/web/notification/${id}/show`)
      .then(res => {
        // successAlert()
      })
      .catch(err => {
        console.log(err)
      })
    await dispatch(getNotification(getState().notifications.params))
    return id
  }
)

export const addNotification = createAsyncThunk(
  'notifications/addNotification',
  async (user, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + '/api/users/notify_topic', user, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        successAlert()
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          errorAlert(err?.response?.data?.message)
        } else {
          errorAlert(err)
        }
      })
    await dispatch(getNotification(getState().notifications.params))
    return user
  }
)
export const addNotificationUser = createAsyncThunk(
  'notifications/addNotificationUser',
  async (params, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/api/users/notify_user/${params.id}`, params.data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        successAlert()
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          errorAlert(err?.response?.data?.message)
        } else {
          errorAlert(err)
        }
      })
    await dispatch(getNotification(getState().notifications.params))
    return params.id
  }
)
export const updateNotifications = createAsyncThunk(
  'notifications/updateNotifications',
  async (id, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/api/users/allRead`)
      .then(res => {
        // successAlert()
      })
      .catch(err => {
        console.log(err)
      })
    await dispatch(getNotification(getState().notifications.params))
    // return id
  }
)
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    drivers: [],
    users: [],
    un_read: null,
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
        state.un_read = action.payload.un_read
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload
      })
      .addCase(addNew.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload]
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default notificationsSlice.reducer
