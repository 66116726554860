// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('cars/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/cars')
  return response.data.result
})
export const getBrands = createAsyncThunk('cars/getBrands', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/brand?with_paginate=no')
  return response.data.data
})
export const getSubbrands = createAsyncThunk('cars/getSubbrands', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/subBrand?with_paginate=no')
  return response.data.data
})
export const getTypes = createAsyncThunk('cars/getTypes', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/types')
  return response.data.data
})
export const getColors = createAsyncThunk('cars/getColors', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/car/color?with_paginate=no')
  return response.data.data
})
export const getDrivers = createAsyncThunk('cars/getDrivers', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/drivers?paginate=no')
  return response.data.data
})
export const getData = createAsyncThunk('cars/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/cars?', {
    params
  })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.meta.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('cars/getUser', async id => {
  if (id !== null) {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/admin/cars/${id}`)
    return response.data.data
  } else {
    return null
  }
})
export const resetUser = createAsyncThunk('cars/resetUser', () => {
  return null
})

export const addUser = createAsyncThunk('cars/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/admin/cars', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().cars.params))
  return user
})

export const updateUser = createAsyncThunk('cars/updateUser', async (data, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/cars/${data?.id}`, data?.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().cars.params))
  return data.user
})

export const deleteUser = createAsyncThunk('cars/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/admin/cars/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().cars.params))
  return id
})

export const activeUser = createAsyncThunk('cars/activeUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/car/suspend/${user.id}`, user.data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().cars.params))
  return user
})
export const addNewPay = createAsyncThunk('cars/addNewPay', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/admin/payment/downPayment/${user.id}`, user.data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().cars.params))
  return user
})

export const carsSlice = createSlice({
  name: 'cars',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    brands: [],
    subbrands: [],
    colors: [],
    types: [],
    drivers: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.brands = action.payload
      })
      .addCase(getColors.fulfilled, (state, action) => {
        state.colors = action.payload
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload
      })
      .addCase(getSubbrands.fulfilled, (state, action) => {
        state.subbrands = action.payload
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.types = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default carsSlice.reducer
