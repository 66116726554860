// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('statiscs/getAllData', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/auth/caramtaxi/admin/statiscs?with_paginate=yes'
  )
  return response.data.result
})

export const getData = createAsyncThunk('statiscs/getData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/statistics/totalusers', { params })
  return {
    params,
    data: response.data.data,
    // totalPages: response.data.data.total,
    loading: true
  }
})
export const getMainData = createAsyncThunk('statiscs/getMainData', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/statistics/usercounter', { params })
  return {
    params,
    mainData: response.data.data,
    // totalPages: response.data.data.total,
    loading: true
  }
})
export const getTrips = createAsyncThunk('statiscs/getTrips', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/statistics/tripscounter', { params })
  return {
    params,
    tripData: response.data.data,
    // totalPages: response.data.data.total,
    loading: true
  }
})
export const getCompanyMoney = createAsyncThunk('statiscs/getCompanyMoney', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/statistics/companymoney', { params })
  return {
    params,
    companyData: response.data.data,
    // totalPages: response.data.data.total,
    loading: true
  }
})

export const getChart = createAsyncThunk('statiscs/getChart', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/admin/statistics/chart?', { params })
  return {
    chartData: response.data.data
  }
})
export const getUser = createAsyncThunk('statiscs/getUser', async id => {
  if (id !== null) {
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/v1/web/statiscs/${id}/edit`)
    return response.data.result
  } else {
    return null
  }
})

export const addUser = createAsyncThunk('statiscs/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/auth/caramtaxi/admin/statiscs/store', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().statiscs.params))
  return user
})

export const updateUser = createAsyncThunk('statiscs/updateUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/auth/caramtaxi/admin/statiscs/update`, user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().statiscs.params))
  return user
})

export const deleteUser = createAsyncThunk('statiscs/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/auth/caramtaxi/admin/statiscs/delete`, { id: id })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().statiscs.params))
  return id
})
export const enableOcc = createAsyncThunk('statiscs/enableOcc', async (id, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/auth/caramtaxi/admin/statiscs/enable`, { id: id })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().statiscs.params))
  return id
})
export const disableOcc = createAsyncThunk('statiscs/disableOcc', async (id, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/auth/caramtaxi/admin/statiscs/disable`, { id: id })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().statiscs.params))
  return id
})
export const statiscslice = createSlice({
  name: 'statiscs',
  initialState: {
    data: null,
    mainData: null,
    tripData: null,
    chartData: null,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        // state.total = action.payload.totalPages;
        state.loading = action.payload.loading
      })
      .addCase(getChart.fulfilled, (state, action) => {
        state.chartData = action.payload.chartData
      })
      .addCase(getMainData.fulfilled, (state, action) => {
        state.mainData = action.payload.mainData
        state.params = action.payload.params
        // state.total = action.payload.totalPages;
        state.loading = action.payload.loading
      })
      .addCase(getTrips.fulfilled, (state, action) => {
        state.tripData = action.payload.tripData
        state.params = action.payload.params
        // state.total = action.payload.totalPages;
        state.loading = action.payload.loading
      })
      .addCase(getCompanyMoney.fulfilled, (state, action) => {
        state.companyData = action.payload.companyData
        state.params = action.payload.params
        // state.total = action.payload.totalPages;
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default statiscslice.reducer
